/* html,body {
  margin: 0;
  height: 100%;
  background: url('../img/APEPS.png');
  background-repeat: no-repeat;
} */
body {
  margin: 0;
 
}
/* /assets/img/home/portada.jpg */
.custom-scrollbar::-webkit-scrollbar {
  width: 0.5em;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #e8e8e8;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 0.5em;
}

.react-pdf__Page {
  overflow: auto;
}

.g-recaptcha {
  display: inline-block;
}

.Toastify__toast--warning {
  background: #f89616;
}

.recaptcha-container > div > div > div{
  margin: 0px auto!important;
}

.recaptcha-container > p {
  text-align: center;
}

.react-pdf__Page {
  overflow: hidden;
}

.react-pdf__Page > canvas {
  max-width: 100%;
  height: auto !important;
  margin: 0 auto;
}
@media screen and (max-width: 500px) {
  .recaptcha-container {
    margin: 0px auto!important;
    padding-bottom: 25px;
  }
  .makeStyles-formBox-9 {
    padding: 20px !important;
  }
  .recaptcha-container > div {
    height: 78px;
  }
  .recaptcha-container > div > div {
    transform-origin: 0 0;
  }

  .recaptcha-container > p {
    margin-left: 0;
  }
}
@media screen and (max-width: 360px) {
  .recaptcha-container {
    width: 280px;
    margin: 0px auto!important;
    padding-bottom: 25px;
  }
  .recaptcha-container > div {
    height: 68px;
  }
  .recaptcha-container > div > div {
    transform:scale(0.92);
    transform-origin: 0 0;
  }

  .recaptcha-container > p {
    margin-left: 0;
  }
}

@media print{
  .oculto-impresion, .oculto-impresion *{
    display: none !important;
  }
}


._encuesta .MuiContainer-root{
  padding: 0px !important;
}

#container-circles{
  bottom: 150px !important;
}